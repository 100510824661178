import queryParser from "./queryParser.js";
import asyncImages from "./unsplash-images/index.js";
export async function fetchImageData(id) {
  if (!asyncImages[id])
    return null;
  const {default: data} = await asyncImages[id]();
  return data;
}
export const hasImage = async (id) => Object.keys(asyncImages).includes(id);
export function getRandomImage() {
  const keys = Object.keys(asyncImages);
  return keys[Math.floor(Math.random() * keys.length)];
}
export const getRandomImageData = async () => {
  const keys = Object.keys(asyncImages);
  const randomKey = keys[Math.floor(Math.random() * keys.length)];
  return fetchImageData(randomKey);
};
export default async function unsplashPhoto() {
  const query = queryParser();
  const availePhoto = await hasImage(query.get("photos"));
  let photo = null;
  if (!availePhoto) {
    photo = getRandomImage();
  } else {
    photo = query.get("photos");
  }
  if (photo) {
    query.set("photos", photo);
    const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${query.toString()}`;
    !availePhoto && window.history.pushState({path: newUrl}, "", newUrl);
  }
  if (photo) {
    return fetchImageData(photo);
  }
  return null;
}
