import {decode} from "../_snowpack/pkg/blurhash.js";
let favicon;
window.addEventListener("load", () => {
  favicon = document.querySelector('[rel="shortcut icon"]');
});
export default async function createFavicon({
  color,
  blurHash
}) {
  favicon = document.querySelector('[rel="shortcut icon"]');
  const pixels = decode(blurHash, 32, 32);
  console.log({pixels});
  const faviconSize = 32;
  const canvas = document.createElement("canvas");
  canvas.width = faviconSize;
  canvas.height = faviconSize;
  canvas.classList.add("blurry");
  const context = canvas.getContext("2d");
  const img = document.createElement("img");
  if (favicon?.href)
    img.src = favicon.href;
  document.body.appendChild(canvas);
  console.log(document.body.firstChild);
  document.body.insertBefore(canvas, document.body.firstChild);
  return new Promise((resolve) => {
    img.onload = () => {
      if (context && favicon) {
        const imageData = context.createImageData(faviconSize, faviconSize);
        imageData.data.set(pixels);
        context.putImageData(imageData, 0, 0);
        favicon.type = "image/png";
        favicon.href = canvas.toDataURL("image/png");
        resolve(canvas.toDataURL("image/png"));
      }
    };
  });
}
