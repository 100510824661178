import confetti from "../_snowpack/pkg/canvas-confetti.js";
import unsplash from "./unsplash.js";
(async () => {
  await unsplash();
  document.documentElement.classList.add("hasConfetti");
  await confetti.create(document.getElementById("canvas"), {
    resize: true,
    useWorker: true
  })({particleCount: 200, spread: 200});
  document.documentElement.classList.remove("hasConfetti");
})();
